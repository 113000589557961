.footer {
  width: 100%;
  padding: 48px 0px;
  margin-top: 50px;

  @include themify($themes) {
    background: $color-footer;
  }
}
.footer-text-left {
  font-weight: 600;
  line-height: 10px;
  margin-bottom: 0;
  font-size: 13px;
  color: #7f8b95!important;
  text-decoration: none;

  a {
    color: #7f8b95;
  }}

.footer__nav-link {
  color: #7f8b95;
  text-decoration: none;
  margin-right: 15px;
  font-weight: 400;

  &:hover {
    color: #7f8b95;
  }

}