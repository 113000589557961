.badge--Pago {
  background: $color-green;
}

.badge--Pendente {
  color: $color-text;
  background: $color-yellow;
}

.badge--Aguardando {
  background: $color-blue;
}

.badge--Falhou {
  background: $color-red;
}