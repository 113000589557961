.responsive-image {
  position: relative;
  max-width: 100%;
}

.certificado {
  &.active {
    .responsive-image__image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 60%;
      height: 62%;
      left: 35%;
    }

    #body_certificado {
      left: 250.537px;
      top: 13%;
      position: absolute;
      overflow: visible;
      width: 60%;
      height: 646.2507934570312px;
      line-height: 32.3668327331543px;
      margin-top: -2.6972360610961914px;
      text-align: left;
      font-family: MyriadPro-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      color: rgba(26, 24, 24, 1);
      .introducao {
        margin-bottom: 10px;
      }
      .titulo_certificado {
        font-size: 20.96315002441406px;
        font-weight: bolder;
        margin-bottom: 15px;
      }
      .data_atual {
        margin-top: 6px;
        margin-left: 55%;
        margin-bottom: 15px;
      }
    }

    #rodape {
      left: 260px;
      top: 113%;
      position: absolute;
      overflow: visible;
      align-content: center;
      width: 363px;
      text-align: left;
      font-family: Myanmar MN;
      font-style: normal;
      font-weight: 200;
      font-size: 13px;
      line-height: 15.3668327331543px;
      color: rgba(26, 24, 24, 1);
    }
  }
}

.responsive-image__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#Retngulo_1 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#body_certificado {
  left: 80.537px;
  top: 226.131px;
  position: absolute;
  overflow: visible;
  width: 800.373px;
  height: 646.2507934570312px;
  line-height: 32.3668327331543px;
  margin-top: -2.6972360610961914px;
  text-align: left;
  font-family: MyriadPro-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 26.972360610961914px;
  color: rgba(26, 24, 24, 1);
  .introducao {
    margin-bottom: 35px;
  }
  .titulo_certificado {
    font-size: 35.96315002441406px;
    font-weight: bolder;
    margin-bottom: 35px;
  }
  .data_atual {
    margin-top: 50px;
    margin-left: 55%;
    margin-bottom: 15px;
  }
}

#rodape {
  left: 100px;
  top: 1153px;
  position: absolute;
  overflow: visible;
  align-content: center;
  width: 363px;
  text-align: left;
  font-family: Myanmar MN;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 15.3668327331543px;
  color: rgba(26, 24, 24, 1);
}

@media screen and (max-width: 1100px) {
  #body_certificado {
    left: 69.537px;
    top: 199.131px;
    width: 738.373px;
    font-size: 21px;
    height: 646.2507934570312px;
    margin-top: -2.6972360610961914px;
    .introducao {
      margin-bottom: 10px;
    }
    .titulo_certificado {
      font-size: 22.96315002441406px;
      font-weight: bolder;
      margin-bottom: 10px;
    }
    .data_atual {
      margin-top: 5px;
      margin-left: 55%;
      margin-bottom: 10px;
    }
  }
  #rodape {
    left: 30.537px;
    top: 958.131px;
    line-height: 18.3668327331543px;
    margin-top: -2.6972360610961914px;
    font-size: 18.972360610961914px;
  }
}

@media screen and (max-width: 900px) {
  #body_certificado {
    left: 49.537px;
    top: 159.131px;
    width: 538.373px;
    font-size: 18px;
    height: 646.2507934570312px;
    margin-top: -2.6972360610961914px;
    .introducao {
      margin-bottom: 10px;
    }
    .titulo_certificado {
      font-size: 22.96315002441406px;
      font-weight: bolder;
      margin-bottom: 10px;
    }
    .data_atual {
      margin-top: 5px;
      margin-left: 55%;
      margin-bottom: 10px;
    }
  }
  #rodape {
    left: 30.537px;
    top: 728.131px;
    line-height: 14.3668327331543px;
    margin-top: -2.6972360610961914px;
    font-size: 15.972360610961914px;
  }

  @media screen and (max-height: 900px) {
    #rodape {
      top: 388.131px;
    }
  }
}

@media screen and (max-width: 700px) {
  #body_certificado {
    left: 29.537px;
    top: 126.131px;
    width: 408.373px;
    height: 646.2507934570312px;
    line-height: 18.3668327331543px;
    margin-top: -2.6972360610961914px;
    font-size: 13.972360610961914px;
    .introducao {
      margin-bottom: 10px;
    }
    .titulo_certificado {
      font-size: 10.96315002441406px;
      font-weight: bolder;
      margin-bottom: 10px;
    }
    .data_atual {
      margin-top: 5px;
      margin-left: 55%;
      margin-bottom: 10px;
    }
  }
  #rodape {
    top: 508.131px;
    left: 30.537px;
    line-height: 8.3668327331543px;
    margin-top: -2.6972360610961914px;
    font-size: 12.972360610961914px;
  }

  @media screen and (max-height: 900px) {
    #rodape {
      top: 528.131px;
    }
  }
}

@media screen and (max-width: 500px) {
  #body_certificado {
    left: 29.537px;
    top: 100.131px;
    width: 278.373px;
    height: 646.2507934570312px;
    line-height: 18.3668327331543px;
    margin-top: -2.6972360610961914px;
    font-size: 8.972360610961914px;
    .introducao {
      margin-bottom: 10px;
    }
    .titulo_certificado {
      font-size: 10.96315002441406px;
      font-weight: bolder;
      margin-bottom: 10px;
    }
    .data_atual {
      margin-top: 5px;
      margin-left: 55%;
      margin-bottom: 10px;
    }
  }
  #rodape {
    left: 30.537px;
    top: 418.131px;
    line-height: 2.3668327331543px;
    margin-top: -2.6972360610961914px;
    font-size: 7.972360610961914px;
  }
}

@media screen and (max-width: 400px) {
  #body_certificado {
    left: 20.537px;
    top: 88.131px;
    width: 278.373px;
    height: 646.2507934570312px;
    line-height: 18.3668327331543px;
    margin-top: -2.6972360610961914px;
    font-size: 8.972360610961914px;
    .introducao {
      margin-bottom: 10px;
    }
    .titulo_certificado {
      font-size: 10.96315002441406px;
      font-weight: bolder;
      margin-bottom: 10px;
    }
    .data_atual {
      margin-top: 5px;
      margin-left: 55%;
      margin-bottom: 10px;
    }
  }
  #rodape {
    left: 30.537px;
    top: 418.131px;
    height: 646.2507934570312px;
    line-height: 2.3668327331543px;
    margin-top: -2.6972360610961914px;
    font-size: 7.972360610961914px;
  }
  @media screen and (max-height: 900px) {
    #rodape {
      top: 378.131px;
    }
  }
}

@media screen and (max-width: 350px) {
  #body_certificado {
    left: 20.537px;
    top: 95.131px;
    width: 228.373px;
    height: 646.2507934570312px;
    line-height: 10.3668327331543px;
    margin-top: -2.6972360610961914px;
    font-size: 7.972360610961914px;
    .introducao {
      margin-bottom: 10px;
    }
    .titulo_certificado {
      font-size: 10.96315002441406px;
      font-weight: bolder;
      margin-bottom: 10px;
    }
    .data_atual {
      margin-top: 5px;
      margin-left: 55%;
      margin-bottom: 10px;
    }
  }
  #rodape {
    left: 30.537px;
    top: 320.131px;
    height: 646.2507934570312px;
    line-height: 0.3668327331543px;
    margin-top: -2.6972360610961914px;
    font-size: 5.972360610961914px;
  }

  @media screen and (max-width: 300px) {
    #body_certificado {
      width: 208.373px;
      font-size: 5.972360610961914px;
    }
    #rodape {
      top: 260.131px;
      font-size: 3.972360610961914px;
      line-height: 1%;
    }
  }
}
