.container {

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
    padding: 0 3%;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 90px;
  min-height: 80vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: $color-white;
  }

  @media screen and (min-width: 576px) {
    padding-left: 250px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 10px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}